import React, { useContext, useState } from "react";
import styled from "styled-components";
import Police from "./Police.png";
import Army from "./Army.png";
import FireFighters from "./FireFighters.png";
import Support from "./Support.png";
import SupportGroup from "./SupportGroup.png";
import { hospitalBusIconV2 } from "../../../../Auth/landingPage/images";
import MuiButton from "../../../../../kit/Button/MuiButton";
import { FlexColumn, FlexRow } from "../../../../Common/Styled";
import { EmergencyOverlaysContext } from "../../EmergencyOverlaysContext";
import useHandleSendMessage from "../../hooks/handleSendMessage";
import useHandleMakeCall from "../../hooks/handleMakeCall";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ButtonDoubleBorder } from "../../../../Common/Styled";

const Wrapper = styled.div``;
const Title = styled.h2`
  margin: 0;
  color: #337a37;
  text-align: center;
  font-family: Assistant;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin-bottom: 10px;
`;
const OverlayCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: ${({ selected, selectedColor }) =>
    selected ? selectedColor ?? "#7FB383" : "f3f8f1"};
  box-shadow: 2px 2px 5px 1px rgba(70, 57, 77, 0.5);
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px;
  height: 60px;
`;
const Name = styled.h4`
  margin: 0;
  color: ${({ selected }) => (selected ? "#FFF" : "#337a37")};
  text-align: right;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  direction: rtl;
  margin-left: auto;
`;

const buttonStyles = {
  fontSize: 20,
  fontWeight: 100,
  fontFamily: "NotoSans-600",
  borderRadius: "10px",
  height: 36,
  margin: "25px auto 0",
  textTransform: "none",
  display: "flex",
  justifyContent: "space-around",
  color: "#fff",
};

const Contacts = [
  {
    name: "משטרה",
    phone: "+972509540490",
    clickDescription: "לחצו לבחירת איש קשר",
    img: Police,
  },
  {
    name: "צבא וביטחון",
    phone: "+972509540490",
    clickDescription: "צבא וביטחון",
    img: Army,
  },
  {
    name: "כיבוי והצלה",
    phone: "+972509540490",
    clickDescription: "לחצו לבחירת איש קשר",
    img: FireFighters,
  },
  {
    name: "רפואה",
    phone: "+972523153355",
    clickDescription: "לחצו לבחירת איש קשר",
    img: hospitalBusIconV2,
  },
  {
    name: "מוקדים חירום עירוניים",
    clickDescription: "לחצו לבחירת איש קשר",
    phone: "+972523153355",
    img: Support,
    imgWidth: "44px",
    imgHeight: "44px",
  },
  {
    name: "אנשי קשר לחירום",
    clickDescription: "לחצו לבחירת איש קשר",
    phone: "+972523153355",
    img: SupportGroup,
    imgWidth: "51px",
    imgHeight: "51px",
  },
];

const EmergencyContact = ({ onClose, emergencyId }) => {
  const { theme } = useContext(EmergencyOverlaysContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isCalling, setIsCalling] = useState(false);

  const { sendMessage } = useHandleSendMessage();
  const { makeCall } = useHandleMakeCall();

  const onClick = (index) => {
    if (selectedOptions.includes(index)) {
      setSelectedOptions(selectedOptions.filter((n) => n !== index));
      return;
    }
    setSelectedOptions(selectedOptions.concat([index]));
  };

  const generateShareContent = ({ shareData, urlRecordId }) => {
    const domain = window.location.origin;
    const fullUrl = `${domain}/emergency/OverlayEmergencyMap/${urlRecordId}`;
    return `${shareData.title}\n${shareData.text}\n${fullUrl}\n`;
  };

  const handleSendSmsClick = async () => {
    setIsSendingMessage(true);
    const selectedContacts = selectedOptions.map((index) => Contacts[index]);
    const shareData = {
      title: "🆘 Together Ecosystem - Emergency",
      text: "🚨 נשלחה אליך בקשת סיוע דחוף מחבר/ה קהילתך. לפרטים נוספים יש ללחוץ על הקישור/ המפה המצורפים.",
    };

    const message = generateShareContent({
      shareData,
      urlRecordId: emergencyId,
    });

    try {
      for (const contact of selectedContacts) {
        await sendMessage({
          to: contact.phone,
          body: message,
        });
        console.log(`Message sent to ${contact.name} (${contact.phone})`);
      }
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Failed to send SMS:", error);
    } finally {
      setIsSendingMessage(false);
    }
  };

  const handleMakeCallClick = async () => {
    setIsCalling(true);
    const selectedContacts = selectedOptions.map((index) => Contacts[index]);

    try {
      for (const contact of selectedContacts) {
        await makeCall({ to: contact.phone });
      }
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Failed to make call:", error);
    } finally {
      setIsCalling(false);
    }
  };

  if (showSuccessMessage) {
    return (
      <FlexColumn
        sx={{
          color: "#337A37",
          fontFamily: "Assistant",
          fontSize: 25,
          fontWeight: 700,
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          padding: "15px 0",
        }}
      >
        <div>הודעתך למוקדי החירום </div>
        <div>נשלחה בהצלחה</div>
        <FlexRow alignItems={"center"} gap={"10px"}>
          <div>Confirmed</div>
          <IoIosCheckmarkCircle color={"#4BAE4F"} fontSize={40} />
        </FlexRow>
      </FlexColumn>
    );
  }

  return (
    <Wrapper>
      <Title>הודעה ישירה למוקדי החירום</Title>
      <FlexColumn sx={{ gap: "15px" }}>
        {Contacts.map((ec, index) => (
          <OverlayCard
            key={index}
            selected={selectedOptions.includes(index)}
            selectedColor={theme?.tertiary ?? theme?.primary}
            onClick={() => onClick(index)}
          >
            {ec.img && (
              <img
                style={{
                  width: ec?.imgWidth ?? "58px",
                  height: ec?.imgHeight ?? "58px",
                }}
                src={ec.img}
                alt={"icon"}
              />
            )}
            <Name selected={selectedOptions.includes(index)}>{ec.name}</Name>
          </OverlayCard>
        ))}
      </FlexColumn>
      {selectedOptions.length > 0 && (
        <FlexRow sx={{ justifyContent: "space-around", gap: "20px" }}>
          <ButtonDoubleBorder>
            <MuiButton
              onClick={handleMakeCallClick}
              disabled={isCalling}
              sx={{
                ...buttonStyles,
                border: " thick double #FFFFFF",
                backgroundColor: isCalling
                  ? theme?.primary
                  : theme?.tertiary ?? "#EF7300",
                width: 130,
                "&:hover": {
                  backgroundColor: theme?.primary ?? "#EF7300",
                },
              }}
            >
              {isCalling ? (
                "Calling..."
              ) : (
                <>
                  <div>Call</div>
                  <div>שיחה</div>
                </>
              )}
            </MuiButton>
          </ButtonDoubleBorder>

          <ButtonDoubleBorder>
            <MuiButton
              onClick={handleSendSmsClick}
              disabled={isSendingMessage}
              sx={{
                border: "thick double #FFFFFF",
                ...buttonStyles,
                backgroundColor: isSendingMessage
                  ? theme?.tertiary
                  : theme?.primary ?? "#EF7300",
                width: 178,
                height: 45,
                borderRadius: "10px",
                padding: "6px",
                margin: "16px auto 0",
                "&:hover": {
                  backgroundColor: theme?.primary ?? "#EF7300",
                },
              }}
            >
              {isSendingMessage ? (
                "Sending..."
              ) : (
                <>
                  <div>Send</div>
                  <div>שלח</div>
                </>
              )}
            </MuiButton>
          </ButtonDoubleBorder>
        </FlexRow>
      )}
    </Wrapper>
  );
};

export default EmergencyContact;
